import Seo from "components/Seo";
import Layout from "components/Organisms/Layout";
import { Box, Typography } from "@mui/material";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";

function Custom404() {
  const { t } = useTranslation("common");
  return (
    <Layout variant="secondary">
      <Seo title={"404 - DOMMI"} description="404 - DOMMI" />
      <Box
        p={10}
        display="flex"
        flex={1}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h2">{t("errors.Page not found")}</Typography>
        <Typography variant="h3">Status: 404</Typography>
      </Box>
    </Layout>
  );
}

export const getStaticProps = async (context) => {
  const { locale } = context;

  return {
    props: {
      ...(await serverSideTranslations(locale, ["navigation", "common"])),
    },
  };
};

export default Custom404;
